import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from 'apollo-cache-inmemory'
import introspectionQueryResultData from 'fragmentTypes.json'
import { configureStore } from './redux/store'
import * as serviceWorker from './serviceWorker'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL || 'http://localhost:4444/gql', // URL of the GraphQL server,
  credentials: 'include',
  cache: new InMemoryCache({ fragmentMatcher })
})

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'))

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className='loading' />}>
      <ApolloProvider client={client}>
        <App client={client} />
      </ApolloProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()
