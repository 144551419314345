import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { auth } from '../../helpers/Firebase'
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD
} from '../actions-types'

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError
} from './actions'

const loginWithEmailPasswordAsync = async (email, password) =>
  auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error)

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password)
    if (!loginUser.message) {
      localStorage.setItem('user_id', loginUser.user.uid)
      yield put(loginUserSuccess(loginUser.user))
      history.push('/')
    } else {
      yield put(loginUserError(loginUser.message))
    }
  } catch (error) {
    yield put(loginUserError(error))
  }
}

const registerWithEmailPasswordAsync = async (email, password) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error)

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    )
    if (!registerUser.message) {
      localStorage.setItem('user_id', registerUser.user.uid)
      yield put(registerUserSuccess(registerUser))
      history.push('/')
    } else {
      yield put(registerUserError(registerUser.message))
    }
  } catch (error) {
    yield put(registerUserError(error))
  }
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error)
  history.push('/')
}

function* logout({ payload }) {
  const { history } = payload
  try {
    yield call(logoutAsync, history)
    localStorage.removeItem('user_id')
  } catch (error) {
    // log error
  }
}

const forgotPasswordAsync = async (email) =>
  auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error)

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email)
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'))
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message))
    }
  } catch (error) {
    yield put(forgotPasswordError(error))
  }
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) =>
  auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error)

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    )
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'))
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message))
    }
  } catch (error) {
    yield put(resetPasswordError(error))
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword)
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword)
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout)
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword)
  ])
}
